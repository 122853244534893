import styled from "styled-components";

export const Content = styled.div`
    height: calc(100vh - 61px);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Inner = styled.div`
    text-align: center;
`;
