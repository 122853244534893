import * as React from "react";
import Master from "src/components/Master";
import Page from "src/components/notFoundPage/Page";

const NotFoundPage = () => (
    <Master title={"Seite nicht gefunden"}>
        <Page />
    </Master>
);

export default NotFoundPage;
