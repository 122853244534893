import * as React from "react";
import ContentWrapper from "src/components/common/ContentWrapper";

import { pageUrls } from "../../helpers";
import GatsbyLink from "../common/GatsbyLink";
import LightenBackground from "../common/LightenBackground";
import Text, { variants } from "../common/Text";
import * as sc from "./Page.sc";

const Page: React.FC = () => (
    <LightenBackground>
        <ContentWrapper>
            <sc.Content>
                <sc.Inner>
                    <Text variant={variants.headline1}>404 - Die aufgerufene Seite existiert nicht.</Text>
                    <Text>
                        Kontaktdaten, das Impressum und grundlegende Informationen zur Firma finden Sie auf der{" "}
                        <GatsbyLink to={pageUrls.home}>Startseite</GatsbyLink>.
                    </Text>
                    <Text>
                        Informationen zum Datenschutz finden Sie in der <GatsbyLink to={pageUrls.privacy}>Datenschutzerklärung</GatsbyLink>.
                    </Text>
                </sc.Inner>
            </sc.Content>
        </ContentWrapper>
    </LightenBackground>
);

export default Page;
